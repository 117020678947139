.section-clinica{
    padding: 50px 0px;

    .descricao-clinica{
        font-size: 20px;
        line-height: 34px;
        font-weight: 400;
        color: #505050;
    }

    .fotos{
        background: #fff;
    }

    .container-img-galeria {
        position: relative;

        .mais {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 35px;
            z-index: 3;

            @media(max-width: 767px) {
                top: calc(50% - 18px);
                right: calc(50% - 18px);
            }
        }

        .imagem-galeria-efeito {
            transition: all 0.5s ease;
            border-radius: 7px;

            &:hover, &:focus {
                transform: scale(1.01);
                transition: all 0.5s ease;
            }
        }

        &:hover, &:focus {
            .absolute-absolute {
                opacity: 1;
                z-index: 1;
            }

            transition: all 0.5s ease;
        }

        .absolute-absolute {
            position: absolute;
            bottom: 0;
            right: 0;
            top: 0;
            left: 0;
            border-radius: 6.59px;
            background: #27304B94;
            font-family: sans-serif;
            opacity: 0;
            transition: all 0.5s ease;

            .titulo-div {
                text-align: center;
                color: #BDF25A;
                font-size: 27px;
                line-height: 32px;
                font-weight: 700;
                margin-bottom: 10px;
                position: absolute;
                width: 100%;
                top: 40%;

                @media(min-width: 768px) and (max-width: 991px) {
                    font-size: 22px;
                    line-height: 22px;
                }

                @media(max-width: 767px) {
                    display: none;
                }

                span {
                    color: #FFFFFF !important;
                    font-size: 15px !important;
                    line-height: 23px !important;
                    font-weight: 400 !important;

                    @media(min-width: 768px) and (max-width: 991px) {
                        font-size: 12px !important;
                        line-height: 15px !important;
                    }
                }
            }
        }
    }
}

.section-valores{
    padding-bottom: 100px;
    position: relative;

    @media(max-width: 1199px){
        padding-bottom: 150px;
    }

    .missao{
        padding: 45px 45px 80% 45px;

        @media(max-width: 767px){
            padding: 45px;
        }

        .titulo-missao{
            text-align: center;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 35px;
            line-height: 41px;
            margin-bottom: 15px;

            p{
                margin-top: 5px;
            }
        }

        .conteudo-missao{
            font-size: 15px;
            font-weight: 400;
            line-height: 34px;
            color: #fff;
        }
    }

    .visao{
        padding: 45px 45px 80% 45px;

        @media(max-width: 767px){
            padding: 45px;
        }

        .titulo-visao{
            text-align: center;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 35px;
            line-height: 41px;
            margin-bottom: 15px;

            p{
                margin-top: 5px;
            }
        }

        .conteudo-visao{
            font-size: 15px;
            font-weight: 400;
            line-height: 34px;
            color: #fff;
        }
    }

    .valores{
        background-color: #F15E42;
        padding: 45px 30px;
        margin-top: -45%;
        border-radius: 6px;

        @media(max-width: 767px){
            border-radius: 0px;
            margin-top: 0px;
            margin-left: -15px;
            margin-right: -15px;
        }

        .titulo-valores{
            text-align: center;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 35px;
            line-height: 41px;
            margin-bottom: 15px;

            p{
                margin-top: 5px;
            }
        }

        .conteudo-valores{
            font-size: 15px;
            font-weight: 400;
            line-height: 34px;
            color: #fff;
            display: inline-block;

            .box{
                padding: 30px 10px 0px 10px;
                width: 100%;

                .titulo-box{
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 34px;
                    color: #fff;
                }

                .conteudo-box{
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 34px;
                    color: #fff;
                }
            }
        }
    }
}

.border-radius-custom{
    @media(min-width: 768px){
        border-radius: 0px 0px 6px 6px;
    }
}
