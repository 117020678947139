.header-1{
    background-color: #F5F5F5;
    color: #8C8C8C;
    font-size: 13px;
    line-height: 22px;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    vertical-align: middle;

    a{
        color: inherit !important;
    }

    .alinha-direita{
        @media(min-width: 1200px){
            text-align: right !important;
        }
    }

   .box-icones{

       @media(max-width: 1199px){
           text-align: center;
       }

       i{
           color: #F15E42;
           font-size: 14px;
       }

       .margem-esquerda-resolucoes-grandes{
           @media(min-width: 992px){
               margin-left: 15px;
           }
       }
   }
}

.header-2{
    background-color: #fff;
    position: relative;

    .logo-absolute{
        max-width: 244px;
        transition: all 0.5s ease;

        &:hover, &:focus{
            transform: scale(1.02);
            transition: all 0.5s ease;
        }

        @media(max-width: 991px){
            max-width: 100px;
        }

        @media(min-width: 992px){
            position: absolute;
            top: 0rem;
        }
    }

    .margem-nav-topo{
        @media(min-width: 992px){
            margin-left: 25%;
        }
    }

    .link-efeito-topo{
        color: #27304B;
        font-weight: 500;
        font-size: 18px;
        transition: all 0.5s ease;
        position: relative;
        text-align: center;

        @media(min-width: 992px){
            margin: 2.1rem 0rem;
            padding: 0.2rem 0.5rem;
        }

        &:after{
            content: "";
            position: absolute;
            background-color: #fff;
            height: 4px;
            border-radius: 5px;
            width: 0rem;
            transition: all 0.5s ease;
            left: 0px;
            bottom: 0px;
            right: 0px;
            margin: auto;
        }

        &:hover, &:focus{

            &:after{
                width: 1.5rem;
                transition: all 0.5s ease;
                background-color: #F15E42;
            }

            transition: all 0.5s ease;
        }
    }

    .link-rede-social-topo{
        color: #F15E42;
        font-size: 22px;
        margin: 0px 3px;
        transition: all 0.5s ease;

        &:hover, &:focus{
            transform: translateY(-1px);
            transition: all 0.5s ease;
        }
    }

    .links-redes-sociais-topo-sem-efeito{
        text-align: center;

        @media(min-width: 992px){
            display: flex;
            align-items: center;
        }
    }
}
