.section-equipe-pagina-individual{
    position: relative;
    padding: 0px 0px 150px 0px;

    @media(max-width: 767px){
        padding: 0px 0px 100px 0px;
    }

    .box-veterinario-individual{
        margin-top: 50px;

        .foto-veterinario-individual{
            margin: 0px auto;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 280px;
            height: 280px;
            border-radius: 50%;
        }

        .titulo-veterinario-individual{
            font-size: 25px;
            font-weight: 700;
            line-height: 30px;
            text-align: center;
            color: #3F475E;
            margin-top: 15px;
        }

        .crmv-veterinario-individual{
            color: #3F475E;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
            margin-top: 5px;
        }

        .descricao-veterinario-individual{
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            margin-top: 15px;
            max-height: 140px;
            overflow: hidden;
            color: #505050;
            transition: all 0.5s ease;
        }

        .ver-mais-veterinarios-individual{
            text-align: center;
            margin-top: 15px;

            a{
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                border: 1px #F15E42 solid;
                color: #F15E42;
                background-color: #fff;
                transition: all 0.5s ease;
                border-radius: 48px;
                padding: 10px 22px;
                cursor: pointer;

                &:hover{
                    color: #fff;
                    background-color: #F15E42;
                    transition: all 0.5s ease;
                }

                &:focus{
                    color: #fff;
                    background-color: #F15E42;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .box-colaborador-individual{
        margin-top: 50px;

        .foto-colaborador-individual{
            text-align: center;
            margin: 0px auto;
            background-size: cover;
            border-radius: 50%;
            background-position: center;
            width: 200px;
            height: 200px;
            background-repeat: no-repeat;
        }

        .titulo-colaborador-individual{
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 33px;
            text-align: center;
            color: #3F475E;
            margin-top: 15px;
        }

        .cargo-colaborador-individual{
            color: #3F475E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
            margin-top: 5px;
        }
    }
}

.foto-equipe-principal{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    margin-top: 50px;
    padding-top: 40%;
}
