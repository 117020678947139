.section-servicos-pagina-individual{
    position: relative;

    .botoes-servicos{
        margin: 50px 0px;

        .link-servico{
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
            width: 100%;
            height: 96px;
            text-align: center;
            transition: all 0.5s ease;
            color: #F15E42;
            background-color: #fff;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            border: 1px #F15E42 solid;
            border-radius: 6px;
            cursor: pointer;
            padding: 0px 15px;

            &:hover, &:focus{
                transition: all 0.5s ease;
                background-color: #F15E42;
                color: #fff;
            }
        }
    }

    .rede-assistencia{

        .titulo-rede-assistencia{
            color: #3F475E;
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            margin-bottom: 40px;
            position: relative;

            &:after{
                content: "";
                position: absolute;
                background-color: #F15E42;
                height: 4px;
                border-radius: 5px;
                width: 70px;
                transition: all 0.5s ease;
                left: 0px;
                bottom: -12px;
                right: 0px;
                margin: auto;
            }
        }

        .conteudo-rede-assistencia{
            font-size: 20px;
            margin-top: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px;
            text-align: center;
            color: #505050;
        }
    }
}

.section-especialidades-individual{
    margin: 30px 0px;
    padding: 15px 0px 50px 0px;
    background-color: #3F475E;

    .titulo-specialty{
        color: #fff;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 50px;
        position: relative;

        &:after{
            content: "";
            position: absolute;
            background-color: #F15E42;
            height: 4px;
            border-radius: 5px;
            width: 70px;
            transition: all 0.5s ease;
            left: 0px;
            bottom: -12px;
            right: 0px;
            margin: auto;
        }
    }

    .box-especialidade-individual{
        border-radius: 8px;
        padding: 30px 15px;
        background-color: #fff;

        @media(min-width: 540px) and (max-width: 991px){
            height: 100%;
        }

        p{
            font-size: 21px;
            height: 45px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #3F475E;
            display: flex;
            justify-content: left;
            align-items: center;

            @media(min-width: 540px) and (max-width: 991px){
                float: left;
                width: 50%;
                font-size: 18px;
                height: 40px;
                padding-left: 15px;
                padding-right: 15px;
            }

            @media(min-width: 992px) and (max-width: 1199px){
                font-size: 17px;
                height: 40px;
            }

            i{
                color: #70C217;
                margin-right: 10px;
            }
        }
    }
}

.section-descricoes-servicos{
    position: relative;
    padding: 0px 0px 150px 0px;

    @media(max-width: 767px){
        padding: 0px 0px 100px 0px;
    }

    .box-servico-ultimato{
        margin-top: 50px;

        .imagem-servico-ultimato{
            text-align: center;
            margin: 0px auto;
            background-size: cover;
            width: 125px;
            height: 125px;
            background-position: center;
            background-repeat: no-repeat;

            @media(min-width: 768px){
                margin: 0px 0px 0px auto;
            }
        }

        .titulo-servico-ultimato{
            color: #3F475E;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            text-align: left;
            margin-bottom: 15px;
            position: relative;

            @media(min-width: 768px){
                margin-left: 15px;
            }

            @media(max-width: 767px){
                margin-top: 15px;
            }

            &:after{
                content: "";
                position: absolute;
                background-color: #F15E42;
                height: 4px;
                border-radius: 5px;
                width: 70px;
                transition: all 0.5s ease;
                left: 0px;
                bottom: -12px;
                margin: auto;
            }
        }

        .descricao-servico-ultimato{
            font-size: 20px;
            font-style: normal;
            margin-top: 20px;
            font-weight: 400;
            line-height: 34px;
            color: #505050;

            @media(min-width: 768px){
                margin-left: 15px;
            }
        }
    }
}
