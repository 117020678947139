.footer {
    background-color: #27304B;
    position: relative;

    .pb-30{
        padding-bottom: 30px;
    }

    .titulo-rodape{
        color: #fff;
        font-weight: 700;
        font-size: 38px;
        line-height: 43px;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 40px;

        &:after{
            content: "";
            position: absolute;
            background-color: #F15E42;
            height: 4px;
            border-radius: 5px;
            width: 70px;
            transition: all 0.5s ease;
            left: 0px;
            bottom: 30px;
            right: 0px;
            margin: auto;
        }
    }

    .texto-copyright{
        border-top: 1px #4C536A solid;
        color: #fff;
        text-align: center;
        font-size: 12px;
        line-height: 22px;
        font-weight: 400;
        padding: 30px 0px;

        a{
            color: #fff;
        }
    }
}

.informacoes-rodape{
    text-align: left;
    margin-bottom: 30px;

    .titulo-informacao{
        font-weight: 700;
        color: #fff;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 15px;
    }

    .conteudo-informacao{
        color: #fff;
        font-size: 15px;
        line-height: 23px;
        font-weight: 400;
    }
}

.logo{

    img{
        transition: all 0.5s ease;

        &:hover, &:focus{
            transition: all 0.5s ease;
            transform: scale(1.02);
        }

        @media(max-width: 540px){
            max-width: 330px;
        }

        @media(min-width: 541px) and (max-width: 767px){
            max-width: 285px;
        }
    }
}

.redes-sociais-rodape{
    text-align: center;

    i{
        color: #70C217;
        font-size: 30px;
        transition: all 0.5s ease;

        &:hover, &:focus{
            transition: all 0.5s ease;
            transform: translateY(-1px);
        }
    }
}

.pegadas-esquerda{
    position: absolute;
    left: 10px;
    bottom: 10px;

    img{
        width: 20vw;
    }
}

.pegadas-direita{
    position: absolute;
    right: 10px;
    bottom: 10px;

    img{
        width: 20vw;
    }
}

.botao-whatsapp{
    position: fixed;
    right: 15px;
    bottom: 22px;
    z-index: 3;
    opacity: 0;
    transition: all 0.5s ease;

    @media(max-width: 991px){
        border-radius: 50% !important;
        bottom: 8px !important;
        height: 50px !important;
        width: 50px !important;
    }

    a{
        color: #fff;
        transition: all 0.5s ease;
        border-radius: 48px;
        background-color: #26D367;
        border: 1px #26D367 solid;
        font-size: 20px;
        line-height: 23px;
        padding: 10px 26px;

        @media(max-width: 991px){
            border-radius: 50% !important;
            font-size: 26px !important;
            padding: 9px 12.63px !important;
            text-align: center !important;
        }

        &:hover, &:focus{
            background-color: #fff;
            color: #26D367;
            transition: all 0.5s ease;
        }
    }
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}
