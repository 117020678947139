.section-galeria-pagina{
    position: relative;
    padding: 50px 0px 150px 0px;

    @media(max-width: 767px){
        padding: 50px 0px 100px 0px;
    }

    .imagem-galeria-pagina{
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.5s ease;
        margin-top: 10px;

        &:hover, &:focus{
            transform: translateY(-1px);
            transition: all 0.5s ease;
        }
    }

    .imagem-galeria{
        margin-top: 10px;
        border-radius: 6px;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover, &:focus{
            transform: translateY(-1px);
            transition: all 0.5s ease;
        }
    }
}
