.container-banner {
    position: relative;

    .descricao-banner {
        font-size: 68px;
        font-weight: 700;
        top: calc(35%);
        left: calc(12%);
        position: absolute;
        width: 50%;
        line-height: 76px;
        text-align: left;
        max-height: 320px;
        overflow: hidden;
        opacity: 0;
        transform: translateY(80px);
        transition: all 0.5s ease;

        @media(max-width: 540px) {
            font-size: 35px !important;
            top: 0px;
            width: 75%;
            line-height: 40px;
            height: 100%;
            text-align: center;
            padding-top: 50px;
            padding-bottom: 50px;
        }

        @media (min-width: 541px) and (max-width: 767px) {
            text-align: center;
            width: 75%;
            font-size: 35px !important;
            line-height: 40px;
            padding-bottom: 15px;
        }

        @media (min-width: 768px) and (max-width: 991px){
            font-size: 35px !important;
            line-height: 40px;
            padding-bottom: 15px;
        }

        @media(min-width: 992px) and (max-width: 1199px) {
            width: 75%;
        }

        @media(min-width: 1600px) {
            width: 40%;
        }

        .botao-banner-contato {
            color: #fff;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            background-color: #27304B;
            border: 1px #27304B solid;
            transition: all 0.5s ease;
            border-radius: 48px;
            padding: 9px 17px;

            &:hover, &:focus {
                color: #27304B;
                background-color: #fff;
                transition: all 0.5s ease;
            }

            @media(max-width: 540px) {
                color: #fff;
                font-weight: 500;
                font-size: 20px;
                text-align: center;
                background-color: #27304B;
                border: 1px #27304B solid;
                transition: all 0.5s ease;
                border-radius: 48px;
                bottom: 50px;
                width: 100%;
                left: 0px;
                position: absolute;
                padding: 5px 10px !important;
            }

            @media(max-width: 540px){
                bottom: 0px !important;
            }
        }
    }

    .imagem {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        @media(min-width: 1600px){
            height: calc(100vh - 144px);
        }

        @media(min-width: 992px) and (max-width: 1599px){
            height: calc(100vh);
        }

        @media(max-width: 991px) {
            height: 350px !important;
        }
    }

    .prev {
        position: absolute;
        top: calc(50%);
        left: 30px;
        cursor: pointer;
        color: #fff;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F15E42;
        transition: all 0.5s ease;

        &:hover, &:focus {
            color: #F15E42;
            background-color: #fff;
            transition: all 0.5s ease;
        }

        i {
            font-size: 27px;
        }
    }

    .next {
        position: absolute;
        top: calc(50%);
        right: 30px;
        cursor: pointer;
        color: #fff;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F15E42;
        transition: all 0.5s ease;

        &:hover, &:focus {
            color: #F15E42;
            background-color: #fff;
            transition: all 0.5s ease;
        }

        i {
            font-size: 27px;
        }
    }
}

.section-sobre-a-clinica {
    padding-top: 80px;
    padding-bottom: 80px;

    .titulo-sobre {
        color: #272D4E;
        font-weight: 300;
        font-size: 44px;
        line-height: 49px;

        @media(max-width: 768px) {
            margin-top: 35px;
        }

        p {
            color: #F15E42;
            font-weight: 700;
            font-size: 64px;
            line-height: 72px;
            margin: 0px !important;
        }
    }

    .conteudo-sobre {
        margin: 35px 0px 50px 0px;
    }

    .ver-mais-sobre {
        a {
            background-color: #fff;
            color: #F15E42;
            border: 1px #F15E42 solid;
            transition: all 0.5s ease;
            border-radius: 48px;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            padding: 9px 23px;

            &:hover, &:focus {
                color: #fff;
                background-color: #F15E42;
                transition: all 0.5s ease;
            }
        }
    }
}

.section-nossa-equipe {
    background-color: #27304B;
    padding: 50px 0px 65px 0px;
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        height: 50px;
        left: -10%;
        right: -10%;
        border-radius: 50%;
        top: -25px;
        background: #fff;
    }

    &:after {
        content: "";
        position: absolute;
        height: 50px;
        left: -10%;
        right: -10%;
        border-radius: 50%;
        bottom: -25px;
        background-color: #EEEEEE;
    }

    .titulo-section {
        color: #fff;
        font-weight: 700;
        font-size: 38px;
        line-height: 43px;
        text-align: center;
        margin-bottom: 40px;

        &:after {
            content: "";
            position: absolute;
            background-color: #F15E42;
            height: 4px;
            border-radius: 5px;
            width: 70px;
            transition: all 0.5s ease;
            left: 0px;
            bottom: 30px;
            right: 0px;
            margin: auto;
        }
    }

    .container-equipe {
        position: relative;

        .container-equipe-dados {
            padding: 30px 35px;
            text-align: center;

            .foto {
                height: 210px;
                background-position: center;
                background-size: cover;
                border-radius: 50%;
                width: 210px;
                background-repeat: no-repeat;
                margin-left: auto;
                margin-right: auto;
            }

            .descricao-profissional {

                .nome {
                    color: #fff;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 32px;
                    margin-top: 30px;
                }

                .crm {
                    color: #fff;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 23px;
                    margin-top: 10px;
                }

                .ver-mais-profissional {
                    margin-top: 15px;

                    a, button {
                        color: #BDF25A;
                        background-color: #27304B;
                        font-size: 15px;
                        line-height: 17px;
                        font-weight: 500;
                        border-radius: 36px;
                        border: 1px #BDF25A solid;
                        padding: 7px 20px;
                        transition: all 0.5s ease;

                        &:hover, &:focus {
                            background-color: #BDF25A;
                            color: #27304B;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }

        .prev-equipe {
            position: absolute;
            top: calc(50%);
            left: 30px;
            cursor: pointer;
            color: #fff;
            width: 50px;
            height: 50px;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 100%;
            background-color: #F15E42;
            transition: all 0.5s ease;

            &:hover, &:focus {
                color: #F15E42;
                background-color: #fff;
                transition: all 0.5s ease;
            }

            i {
                font-size: 27px;
            }
        }

        .next-equipe {
            position: absolute;
            top: calc(50%);
            right: 30px;
            cursor: pointer;
            color: #fff;
            width: 50px;
            height: 50px;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 100%;
            background-color: #F15E42;
            transition: all 0.5s ease;

            &:hover, &:focus {
                color: #F15E42;
                background-color: #fff;
                transition: all 0.5s ease;
            }

            i {
                font-size: 27px;
            }
        }
    }

    .tns-nav {
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        button {
            background-color: #fff;
            border: none !important;
            margin: 0px 5px !important;
            height: 10px !important;
            padding: 0px !important;
            width: 10px !important;
            border-radius: 50% !important;
        }

        .tns-nav-active {
            background-color: #F15E42 !important;
        }
    }
}

.section-servicos {
    background-color: #EEEEEE;
    padding: 20px 0px 75px 0px;

    .titulo-section-servicos {
        font-weight: 700;
        font-size: 38px;
        line-height: 43px;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 40px;
        color: #27304B;

        &:after {
            content: "";
            position: absolute;
            background-color: #F15E42;
            height: 4px;
            border-radius: 5px;
            width: 70px;
            transition: all 0.5s ease;
            left: 0px;
            bottom: 30px;
            right: 0px;
            margin: auto;
        }
    }

    .box-servicos {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 4px 8px 0px #00000012;
        margin-bottom: 50px;
        margin-top: 15px;

        .box-conteudo {
            text-align: center;
            padding: 30px;

            .titulo-servico {
                font-weight: 700;
                color: #27304B;
                font-size: 27px;
                line-height: 32px;
                margin-bottom: 25px;

                @media(max-width: 991px) {
                    margin-top: 30px;
                }
            }

            .conteudo-servico {
                color: #505050;
                font-size: 15px;
                line-height: 23px;
                margin-bottom: 15px;
            }

            .link-ver-mais {
                position: absolute;
                cursor: pointer;
                color: #fff;
                width: 50px;
                height: 50px;
                vertical-align: middle;
                justify-content: center;
                align-items: center;
                display: flex;
                bottom: -25px;
                border-radius: 100%;
                background-color: #F15E42;
                transition: all 0.5s ease;
                left: calc(50% - 25px);

                &:hover, &:focus {
                    color: #F15E42;
                    background-color: #fff;
                    transition: all 0.5s ease;
                }

                i {
                    transform: rotate(-90deg);
                    font-size: 27px;
                }
            }
        }
    }

    .ver-todos-servicos {
        margin-top: 30px;

        a {
            color: #F15E42;
            background-color: #EEEEEE;
            border: 1px #F15E42 solid;
            transition: all 0.5s ease;
            font-size: 20px;
            font-weight: 500;
            border-radius: 48px;
            padding: 9px 17px;

            &:hover, &:focus {
                color: #EEEEEE;
                background-color: #F15E42;
                transition: all 0.5s ease;
            }
        }
    }
}

.section-dicas {
    background-color: #f15e42e6;
    height: 300px;
    padding-top: 30px;
    position: relative;
    margin-bottom: 200px;

    @media(max-width: 767px) {
        height: 375px;
    }

    @media(min-width: 768px) and (max-width: 991px) {
        height: 250px;
    }

    .titulo-dicas {
        font-size: 38px;
        line-height: 43px;
        font-weight: 400;
        color: #fff;
        text-align: center;
    }

    .patas-laranja-esquerda {
        position: absolute;
        left: 15px;

        @media(max-width: 767px) {
            top: 300px;
        }

        @media(min-width: 1200px) and (max-width: 1599px) {
            top: 75px;
        }

        @media(min-width: 768px) and (max-width: 991px) {
            top: 80px;
        }

        @media(min-width: 992px) and (max-width: 1199px) {
            top: 125px;
        }

        img {
            width: 540px;

            @media(max-width: 767px) {
                width: 150px;
            }

            @media(min-width: 1200px) and (max-width: 1599px) {
                width: 450px;
            }

            @media(min-width: 768px) and (max-width: 1199px) {
                width: 350px;
            }
        }
    }

    .patas-laranja-direita {
        position: absolute;
        right: 15px;

        @media(max-width: 767px) {
            top: 300px;
        }

        @media(min-width: 1200px) and (max-width: 1599px) {
            top: 75px;
        }

        @media(min-width: 768px) and (max-width: 991px) {
            top: 80px;
        }

        @media(min-width: 992px) and (max-width: 1199px) {
            top: 125px;
        }

        img {
            width: 540px;

            @media(max-width: 767px) {
                width: 150px;
            }

            @media(min-width: 1200px) and (max-width: 1599px) {
                width: 450px;
            }

            @media(min-width: 768px) and (max-width: 1199px) {
                width: 350px;
            }
        }
    }

    .video {
        text-align: center;
        position: relative;
        transition: all 0.5s ease;
        margin-left: 10px;
        margin-right: 10px;
        background-size: cover;
        height: 210px;
        background-repeat: no-repeat;
        background-position: center center;

        &:hover, &:focus {
            transition: all 0.5s ease;
            transform: translateY(-1px);
        }
    }

    .video-active {
        margin-top: 10% !important;

        @media(min-width: 992px) {
            margin-top: 8% !important;
        }

        transition: all 0.5s ease;
    }

    .video-normal {
        margin-top: 10%;

        @media(min-width: 992px) {
            margin-top: 23%;
        }

        transition: all 0.5s ease;
    }

    .img-play {
        width: 80px;
        position: absolute;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
    }

    .prev-dicas {
        position: absolute;
        bottom: 70px;
        left: 30px;
        cursor: pointer;
        color: #fff;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #27304B;
        transition: all 0.5s ease;

        @media(min-width: 992px) and (max-width: 1199px){
            bottom: 60px;
        }

        @media(min-width: 768px) and (max-width: 991px){
            bottom: 80px;
        }

        @media(min-width: 540px) and (max-width: 767px){
            left: 15px;
        }

        @media(max-width: 539px){
            left: 2px;
        }

        &:hover, &:focus {
            color: #27304B;
            background-color: #fff;
            transition: all 0.5s ease;
        }

        i {
            font-size: 27px;
        }
    }

    .next-dicas {
        position: absolute;
        bottom: 70px;
        right: 30px;
        cursor: pointer;
        color: #fff;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #27304B;
        transition: all 0.5s ease;

        @media(min-width: 992px) and (max-width: 1199px){
            bottom: 60px;
        }

        @media(min-width: 768px) and (max-width: 991px){
            bottom: 80px;
        }

        @media(min-width: 540px) and (max-width: 767px){
            right: 15px;
        }

        @media(max-width: 539px){
            right: 2px;
        }

        &:hover, &:focus {
            color: #27304B;
            background-color: #fff;
            transition: all 0.5s ease;
        }

        i {
            font-size: 27px;
        }
    }
}

.section-galeria {
    margin: 50px 0px;

    .titulo-galeria {
        color: #27304B;
        font-weight: 700;
        font-size: 38px;
        line-height: 43px;
        text-align: center;
        margin-bottom: 40px;

        &:after {
            content: "";
            position: absolute;
            background-color: #F15E42;
            height: 4px;
            border-radius: 5px;
            width: 70px;
            transition: all 0.5s ease;
            left: 0px;
            bottom: 30px;
            right: 0px;
            margin: auto;
        }
    }

    .fotos {
        background: #fff;
    }

    .container-img-galeria {
        position: relative;
        margin: 5px;

        .mais {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 35px;
            z-index: 3;

            @media(max-width: 767px) {
                top: calc(50% - 18px);
                right: calc(50% - 18px);
            }
        }

        .imagem-galeria-efeito {
            transition: all 0.5s ease;
            border-radius: 7px;

            &:hover, &:focus {
                transform: scale(1.01);
                transition: all 0.5s ease;
            }
        }

        &:hover, &:focus {
            .absolute-absolute {
                opacity: 1;
                z-index: 1;
            }

            transition: all 0.5s ease;
        }

        .absolute-absolute {
            position: absolute;
            bottom: 0;
            right: 0;
            top: 0;
            left: 0;
            border-radius: 6.59px;
            background: #27304B94;
            font-family: sans-serif;
            opacity: 0;
            transition: all 0.5s ease;

            .titulo-div {
                text-align: center;
                color: #BDF25A;
                font-size: 27px;
                line-height: 32px;
                font-weight: 700;
                margin-bottom: 10px;
                position: absolute;
                width: 100%;
                top: 40%;

                @media(min-width: 768px) and (max-width: 991px) {
                    font-size: 22px;
                    line-height: 22px;
                }

                @media(max-width: 767px) {
                    display: none;
                }

                span {
                    color: #FFFFFF !important;
                    font-size: 15px !important;
                    line-height: 23px !important;
                    font-weight: 400 !important;

                    @media(min-width: 768px) and (max-width: 991px) {
                        font-size: 12px !important;
                        line-height: 15px !important;
                    }
                }
            }
        }
    }

    .ver-todas-galerias {
        margin-top: 50px;

        a {
            color: #F15E42;
            background-color: #fff;
            border: 1px #F15E42 solid;
            transition: all 0.5s ease;
            font-size: 20px;
            font-weight: 500;
            border-radius: 48px;
            padding: 9px 17px;

            &:hover, &:focus {
                color: #fff;
                background-color: #F15E42;
                transition: all 0.5s ease;
            }
        }
    }
}


.imagem-360{
    position: relative;

    .img-play-efeito{
        position: absolute;
        width: 110px;
        height: 110px;
        left: calc(50% - 55px);
        top: calc(50% - 55px);
        transition: all 0.5s ease;

        @media(min-width: 768px) and (max-width: 991px){
            width: 80px;
            height: 80px;
            left: calc(50% - 40px);
            top: calc(50% - 40px);
        }

        @media(max-width: 540px){
            width: 80px;
            height: 80px;
            left: calc(50% - 40px);
            top: calc(50% - 40px);
        }

        &:hover, &:focus{
            transform: scale(1.02);
            transition: all 0.5s ease;
        }
    }
}
